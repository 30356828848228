import React from "react";
import styled from "styled-components";
import { up } from "styled-breakpoints";
import { PageProps, graphql, useStaticQuery } from "gatsby";
import SiteHead from "components/SiteHead";
import Layout from "layout";
import * as UI from "components/ui";
import { Typography } from "styles/mixins";

const Container = styled.article`
  text-align: center;
  color: ${({ theme }) => theme.palette.anotherBlue};
  padding-top: ${({ theme }) => theme.spacing(7)};
  padding-bottom: ${({ theme }) => theme.spacing(7)};

  ${up("lg")} {
    padding-top: ${({ theme }) => theme.spacing(14)};
    padding-bottom: ${({ theme }) => theme.spacing(14)};

    & h1 {
      max-width: 18ch;
      margin-left: auto;
      margin-right: auto;
    }

    & h2 {
      max-width: 50%;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const Content = styled.div`
  h2 {
    ${Typography.t24Leitura}
    color: ${({ theme }) => theme.palette.anotherBlue};
    margin-bottom: ${({ theme }) => theme.spacing(7)};
  }
  p {
    ${Typography.t24Leitura}
    color: ${({ theme }) => theme.palette.anotherBlue};
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;

const NotFound: React.FC<PageProps> = ({ location }) => {
  const { nodeLanding } = useStaticQuery<Gatsby.SpanishNotFoundQueryQuery>(NotFoundQuery);
  const title = nodeLanding?.title as string;
  const content = nodeLanding?.r?.content;

  const getContent = (content: any): string => {
    return content[0]?.body?.value;
  };

  return (
    <Layout location={location} langCode="es">
      <SiteHead title="Página no encontrada" pathname={location.pathname} />
      <Container>
        <UI.Wrap>
          <UI.Text as="h1" typography="h1Leitura" gutter={{ sm: 2, lg: 4 }}>
            {title}
          </UI.Text>
          <Content>
            <UI.RichText content={getContent(content)}></UI.RichText>
          </Content>
        </UI.Wrap>
      </Container>
    </Layout>
  );
};

const NotFoundQuery = graphql`
  query SpanishNotFoundQuery {
    nodeLanding(langcode: { eq: "es" }, path: { alias: { regex: "/404/" } }) {
      title
      r: relationships {
        content: field_content {
          ...TextBlock
        }
      }
    }
  }
`;

export default NotFound;
